import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import picture from './picture.jpg'
import './App.css';
import particlesOptions from "./particles.json";

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <header className="App-header">
                <img src={picture} className="profile-photo" alt="logo"/>
                <p className='profile-name'>
                    Joe Liberi
                </p>
                <div className="profile-description">
                    <p>
                        Unpacking complex problems and coding up simple software solutions – its what I do.
                    </p>
                </div>
                <div className='social-links'>
                    <div className='social-link'>
                        <a
                            className="github-link"
                            href="https://github.com/JoeLiberi"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i><FontAwesomeIcon icon={faGithub} style={{ "color": "black" }}/></i>
                        </a>
                    </div>
                    <div className='social-link'>
                        <a
                            className="linkedin-link"
                            href="https://linkedin.com/in/joseph-liberi-66103b69"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i><FontAwesomeIcon icon={faLinkedin} style={{ "color": "black" }}/></i>
                        </a>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;
